<template>
  <div class="section-couple px-3 px-sm-4 py-5" id="couple">
    <FlowerIl />
    <h1
      data-aos="fade-down"
      data-aos-duration="1500"
      class="h1-couple text-center mb-5"
    >
      We Are Getting Married
    </h1>
    <p data-aos="fade-up" data-aos-duration="1500" class="text-center fs-5">
      Assalamu’alaikum Warahmatullahi Wabarakatuh
    </p>
    <p data-aos="fade-up" data-aos-duration="1500" class="text-center">
      Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta’ala, Kami mengundang
      Bapak/Ibu/Saudara/i, untuk menghadiri Resepsi Pernikahan kami.
    </p>
    <div class="img-wrapper-couple px-3 py-4 mt-4 mb-4">
      <img src="@/assets/photos/female-bride.png" alt="female" />
    </div>
    <h1 data-aos="fade-up" data-aos-duration="1500" class="text-handwrite">
      {{ couple.female.fullname }}
    </h1>
    <p data-aos="fade-up" data-aos-duration="1500" class="text-center">
      <a
        :href="'https://instagram.com/' + couple.female.instagram"
        target="_blank"
        class="align-items-center d-flex justify-content-center"
      >
        <InstaIc width="16" height="16" class="me-1" />
        {{ couple.female.instagram }}
      </a>
      <br />
      Putri dari Bapak {{ couple.female.father }} & Ibu
      {{ couple.female.mother }}
    </p>
    <h1 data-aos="zoom-in" data-aos-duration="1500" class="h1-home my-4">&</h1>
    <div class="img-wrapper-couple px-3 py-4 mb-4">
      <img src="@/assets/photos/male-bride.png" alt="female" />
    </div>
    <h1 data-aos="fade-up" data-aos-duration="1500" class="text-handwrite">
      {{ couple.male.fullname }}
    </h1>
    <p data-aos="fade-up" data-aos-duration="1500" class="text-center mb-5">
      <!-- <a
        :href="'https://instagram.com/' + couple.male.instagram"
        target="_blank"
        class="align-items-center d-flex justify-content-center"
      >
        <InstaIc width="16" height="16" class="me-1" />
        {{ couple.male.instagram }}
      </a> -->
      <br />
      Putra dari Bapak {{ couple.male.father }} & Ibu {{ couple.male.mother }}
    </p>
  </div>
</template>

<script>
const InstaIc = () => import("@/assets/icons/icon-instagram.svg");
const FlowerIl = () => import("@/assets/illustrations/flower-section.svg");

export default {
  name: "CoupleSection",
  props: ["couple"],
  components: { InstaIc, FlowerIl },
};
</script>

<style>
</style>